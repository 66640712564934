import onLoad from '../../../../static/js/onLoad';

/**
 * This class controls the behaviour of the Rainbow Pro navbar.
 *
 * It is designed to be agnostic on how many of these menus there
 * are in the DOM, as you'll need to provide it with the navbar
 * element you want it to control.
 *
 * TODO:
 *  - Close mobile menu of click outside of it?
 *  - Guard against double loading of the script
 *  - Guard against double initialization against the same
 *    DOM element as the navbar root.
 */
export default class RainbowNav {
  navbar: HTMLElement
  constructor(navbar: HTMLElement) {
    this.navbar = navbar;
  }

  /**
   * Initialize the event listeners for this navbar.
   * Must be called right after construction for a functional
   * navigation experience.
   */
  initialize() {
    if (!this.navbar) {
      return;
    }

    /**
     * Add a click listener on the window so any click not covered
     * by the handlers registered for this class collapses the menu.
     */
    window.addEventListener('click', this.collapseAllDropdowns.bind(this));

    this.navbar.querySelectorAll('._nav-btn').forEach((item) => {
      item.addEventListener('click', (clickEvent) => {
        this.expandDropdownMenu(item);
        // We want to stop the propagation here, so we don't
        // close the menu again with the global handler defined
        // above.
        clickEvent.stopPropagation();
      });
    });

    this.navbar
      .querySelector('._toggle-button')
      .addEventListener('click', this.toggleMobileMenu.bind(this));

    // mobile profile menu
    // hidden for countries having subscription disabled
    const mobileProfileToggle = this.navbar.querySelector('._profile-toggle');
    if (mobileProfileToggle) {
      mobileProfileToggle.addEventListener(
        'click',
        this.toggleMobileAccountCard.bind(this)
      );
    }
  }

  /**
   * Toggle the display of the mobile version of the menu.
   * Usually called from the registered click handler on
   * the hamburger icon.
   */
  toggleMobileMenu() {
    // close mobileAccountCard before toggling
    const mobileAccountCard =
      this.navbar.parentNode.getElementsByClassName('_profile-card');
    for (let i = 0; i < mobileAccountCard.length; i++) {
      mobileAccountCard[i].classList.remove('_block');
    }
    const contentToToggle = [
      ...this.navbar.getElementsByClassName('_nav-right'),
      ...this.navbar.getElementsByClassName('_toggle-content'),
    ];
    contentToToggle.forEach((item) => {
      item.classList.toggle('_block');
    });
  }

  toggleMobileAccountCard() {
    // close mobile menu if open
    const contentToToggle = [
      ...this.navbar.getElementsByClassName('_nav-right'),
      ...this.navbar.getElementsByClassName('_toggle-content'),
    ];
    contentToToggle.forEach((item) => {
      item.classList.remove('_block');
    });

    const mobileAccountCard =
      this.navbar.parentNode.getElementsByClassName('_profile-card');
    mobileAccountCard.forEach((item) => {
      item.classList.toggle('_block');
    });
  }

  /**
   * Expands the menu for the given menu item.
   *
   * @param {HTMLElement} menuItem
   */
  expandDropdownMenu(menuItem) {
    const contentItemsToExpand = [
      ...menuItem.parentNode.getElementsByClassName('_nav-content'),
    ];
    this.collapseMatchingDropdowns((i) => {
      return contentItemsToExpand.indexOf(i) === -1;
    });
    contentItemsToExpand.forEach((dropdown) => {
      dropdown.classList.toggle('_block');
    });
  }

  /**
   * Collapses all the dropdown menus that pass the given filter.
   *
   * The filter function get the HTMLElement instance of the `._nav-content`
   * child, and should return true if the menu is to be collapsed.
   *
   * @param {Function} filter
   */
  collapseMatchingDropdowns(filter) {
    if (!this.navbar) {
      return;
    }

    [...this.navbar.getElementsByClassName('_nav-content')]
      .filter(filter)
      .forEach((dropdown) => {
        dropdown.classList.remove('_block');
      });
  }

  collapseAllDropdowns() {
    this.collapseMatchingDropdowns(() => true);
  }
}

function initializeNavbars(target: ParentNode) {
  target.querySelectorAll('._navbar').forEach((navbar) => {
    const navigationController = new RainbowNav(navbar);
    navigationController.initialize();
  });
}

if (typeof htmx !== 'undefined') {
  htmx.onLoad((target) => {
    initializeNavbars(target);
  });
}
